import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import { useTheme } from '@material-ui/core';
import { Typography } from '@material-ui/core';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    const theme = useTheme();
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
                                                    <Logo />
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        variant='h2'
                                                    >
                                                        Quantria 
                                                    </Typography>

        </ButtonBase>
    );
};

export default LogoSection;
