// assets
import { IconBrandFramer, IconTypography, IconPalette, IconShadow, IconWindmill, IconLayoutGridAdd, IconBuildingStore, IconBuildingFortress } from '@tabler/icons';

// constant
const icons = {
    IconTypography: IconTypography,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd,
    IconBuildingStore: IconBuildingStore,
    IconBuildingFortress: IconBuildingFortress
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const utilities = {
    id: 'utilities',
    title: 'Trading',
    type: 'group',
    children: [
        {
            id: 'util-strategies',
            title: 'Marketplace',
            type: 'item',
            url: '/utils/strategies',
            icon: icons['IconBuildingStore'],
            breadcrumbs: false
        },
        {
            id: 'util-apis',
            title: 'Data APIs',
            type: 'item',
            url: '/utils/apis',
            icon: icons['IconWindmill'],
            breadcrumbs: false
        },
        {
            id: 'util-prediction',
            title: 'Prediction',
            type: 'item',
            url: '/utils/prediction',
            icon: icons['IconShadow'],
            breadcrumbs: false
        },
        {
            id: 'util-code',
            title: 'Strategy Builder',
            type: 'item',
            url: '/utils/code_old',
            icon: icons['IconBrandFramer'],
            breadcrumbs: false
        },{
            id: 'util-news',
            title: 'News',
            type: 'item',
            url: '/utils/news',
            icon: icons['IconLayoutGridAdd'],
            breadcrumbs: false
        },{
            id: 'util-square',
            title: 'Town Square',
            type: 'item',
            url: '/utils/square',
            icon: icons['IconBuildingFortress'],
            breadcrumbs: false
        },
    ]
};
